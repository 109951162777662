.hero-section {
  width: 80vw;
  margin: auto;
  padding-top: 50px;
}

.hero-title {
  color: #0065ff;
  font-size: 70px;
  font-weight: bold;
  padding-bottom: 30px;
}

.separator {
  display: flex;
  width: 30%;
  flex-direction: column;
  padding: 25px;
  border-top: 1px solid #0065ff;
}

.bio {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
}

.work {
  padding-top: 10px;
  width: 85%;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.about {
  width: 70%;
  padding-top: 50px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

ul {
  line-height: 2;
}

.projects {
  width: 80vw;
  margin: auto;
}

.project-buttons {
  position: absolute;
  bottom: 30;
}

.card-content {
  position: relative;
}
